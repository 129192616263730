<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="!username">
          {{ $t('auth---Login') }}
        </template>
        <template v-else>
          {{ $t('auth---Welcome, {username}!', { username: username }) }}
        </template>
      </h2>

      <form class="b-form _wide" @submit.prevent="handleLogin">
        <div class="b-row">
          <input
              class="b-input"
              :class="{ _error: !!errors.login }"
              :placeholder="$t('auth---Username, email or phone number')"
              :disabled="step !== 1"
              id="login"
              v-model="login"
              type="text"
              name="login"
              ref="login"
          />
          <button
            v-if="step === 2"
            class="b-icon _input-button"
            type="button"
            @click="resetLogin"
          ><img src="../assets/pencil.svg" alt=""></button>
          <div class="b-error" v-if="errors.login" v-html="errors.login"></div>
        </div>

        <template v-if="step === 1">
          <div class="b-row _space_above _space_below">
            <button class="b-button _full-width" type="submit" :disabled="!login">
              {{ $t('auth---Next') }}
            </button>
          </div>
        </template>

        <template v-else>
          <div class="b-row _space_above">
            <input
                class="b-input"
                :class="{ _error: !!errors.password }"
                :placeholder="$t('auth---Enter password')"
                autofocus
                id="password"
                v-model="password"
                :type="!showPassword ? 'password' : 'text'"
                name="password"
                ref="password"
            />
            <button
                class="b-icon _input-button"
                type="button"
                @click.prevent="showPassword = !showPassword"
            ><img src="../assets/icon__eye.svg" alt=""></button>
            <div
                v-if="errors.password"
                class="b-error"
                v-html="errors.password"
            ></div>
          </div>

          <div class="b-row _space_above">
            <button class="b-button _full-width" type="submit" :disabled="loading">
              {{ $t('auth---Enter') }}
            </button>
          </div>
        </template>

        <div v-if="submitError" class="b-row _error">
          <code>{{ submitError }}</code>
        </div>

        <div :class="!username ? 'b-row _space_above _space_between' : 'b-row _space_above _centered'">
          <a
              v-if="!username"
              href="#"
              class="b-link"
              @click.prevent="$router.push({ name: 'auth-reset-admins' })"
          >{{ $t('auth---I forgot everything') }}</a>
          <a
            v-if="!username"
            :href="hrefForStudent()" class="b-link"
            >
            {{ $t('auth---Login as student') }}
          </a>
          <a v-if="username" href="#" class="b-link" @click.prevent="recoverPassword">
            {{ $t('auth---I forgot my password') }}
          </a>
        </div>
      </form>
    </div>
    <vue-recaptcha
      :sitekey="recaptchaSiteCode"
      ref="recaptcha"
      size="invisible"
      @verify="verifyReCaptcha"
    />
  </Outer>
</template>

<script>
import Outer from '../components/Outer.vue';
import {mapActions, mapState} from 'vuex';
import {VueRecaptcha} from 'vue-recaptcha';
import constants from '../constants/login';

export default {
  components: {
    Outer,
    VueRecaptcha,
  },
  data() {
    return {
      showPassword: false,
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Login');

    if (this.$route.query.login !== undefined && this.$route.query.login !== '') {
      this.login = this.$route.query.login
      this.handleLogin();
    }

    this.$refs.login.focus();
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      step: (state) => state.step,
      username: (state) => state.username,
      errors: (state) => state.errors,
      submitError: (state) => state.submitError,
    }),
    login: {
      get() {
        return this.$store.state.login;
      },
      set(value) {
        this.setError({
          errorId: 'login',
          message: null,
        });
        this.setLogin(value);
      },
    },
    password: {
      get() {
        return this.$store.state.password;
      },
      set(value) {
        this.setError({
          errorId: 'login',
          message: null,
        });
        this.setPassword(value);
      },
    },
    recaptchaSiteCode () {
      return process.env.VUE_APP_RECAPTCHA_SITE_CODE;
    },
  },
  methods: {
    ...mapActions([
      'reset',
      'setError',
      'setLogin',
      'setPassword',
      'setStep',
      'setUsername',
      'apiUserExists',
      'apiLogin',
      'recoverPassword',
    ]),
    hrefForStudent() {
      if (window.location.host.includes('algoritmika.org')) {
        return 'https://learn.algoritmika.org'
      } else if (window.location.host.includes('algoritmika.su')) {
        return 'https://learn.algoritmika.su'
      } else if (window.location.host.includes('alg.academy')) {
        return 'https://learn.alg.academy'
      } else {
        return 'https://learn.alg.academy'
      }
    },
    verifyReCaptcha(response) {
      this.apiLogin(response);
    },
    handleLogin() {
      if (this.step === 1) {
        this.apiUserExists();
        // необходим setTimeout, т.к. при $nextTick поля password ещё нет в DOM
        setTimeout(() => this.$refs.password && this.$refs.password.focus(), 200);

        return;
      }

      this
        .apiLogin()
        .catch(error => {
          if (error === constants.RECAPTCHA_REQUIRED) {
            this.$refs.recaptcha.execute();
          }
        })
    },
    resetLogin() {
      this.reset();
      this.setStep(1);
      this.$nextTick(() => this.$refs.login.focus());
    }
  },
};
</script>
