<template>
  <div class="b-main__inner">
    <div class="b-logo">
      <a href="/">
        <img :src="logoPath" :alt="logoAlt" />
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ukraineHosts: ['lms.logikaschool.com','lms.logikaschool.org']
    }
  },
  computed: {
    logoPath() {
      if (this.ukraineHosts.includes(location.host)) {
        return require('../assets/logika_logo.svg')
      }

      return require('../assets/logo.svg')
    },
    logoAlt() {
      if (this.ukraineHosts.includes(location.host)) {
       return 'Logika'
      }

      return 'Algoritmika';
    }
  }
}
</script>
